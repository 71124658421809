import { render, staticRenderFns } from "./index.vue?vue&type=template&id=a7236528&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=a7236528&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderHomePage: require('/home/etienne/nuxt/AM-frontend/components/HeaderHomePage.vue').default,FirstScreenSliderHomePage: require('/home/etienne/nuxt/AM-frontend/components/FirstScreenSliderHomePage.vue').default,FirstScreenSliderNews: require('/home/etienne/nuxt/AM-frontend/components/FirstScreenSliderNews.vue').default,DiplomesHomePage: require('/home/etienne/nuxt/AM-frontend/components/DiplomesHomePage.vue').default})
