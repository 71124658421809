import { render, staticRenderFns } from "./DiplomesHomePage.vue?vue&type=template&id=38760f95&"
import script from "./DiplomesHomePage.vue?vue&type=script&lang=js&"
export * from "./DiplomesHomePage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports